import { Text } from '@fairlo/ui-kit';
import { ComponentProps } from 'react';
import styled, { WebTarget } from 'styled-components';

const StyledText = styled(Text)`
  margin-bottom: 8px;
`;

type LegendProps = ComponentProps<typeof Text> & {
  as?: WebTarget;
};

export const Legend = (props: LegendProps) => <StyledText as="legend" {...props} />;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid black;
  }
`;

export const TableMark = ({ value }: { value?: { rows?: { cells: string[] }[] } }) => {
  if (!value) {
    return null;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <StyledTable>
        <thead>
          <tr>{value.rows?.[0]?.cells.map((cell, index) => <th key={index}>{cell}</th>)}</tr>
        </thead>
        <tbody>
          {value.rows?.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.cells.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
